import React from 'react';
import { Navbar, Button, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import css from './Header.module.css';
import { NavLink } from 'react-router-dom';

const Header = ({ auth, signout }) => (
  <Navbar expand='lg' variant='dark' className={css.Header}>
    <Navbar.Brand className='d-flex align-items-center'>
      {/* prettier-ignore */}
      <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="1.939%" y1="1.939%" x2="100%" y2="100%" id="a"><stop stopColor="#D4F123" offset="0%"/><stop stopColor="#37F8A2" offset="100%"/></linearGradient></defs><g fill="none" fillRule="evenodd"><rect fill="url(#a)" width="28" height="28" rx="4"/><path d="M14 3c6.064 0 11 4.934 11 11 0 6.065-4.936 11-11 11-6.065 0-11-4.935-11-11C3 7.933 7.935 3 14 3zm0 2.433c-4.723 0-8.566 3.843-8.566 8.566 0 4.724 3.843 8.566 8.566 8.566 4.723 0 8.566-3.842 8.566-8.566 0-4.723-3.843-8.566-8.566-8.566zm-2.682 10.971l5.585 3.836c-2.77 1.153-4.712.333-4.828.282l-.005-.003-.752-4.115zm-.906-2.71l1.252 6.724c-2.758-1.181-3.552-3.158-3.598-3.276l-.002-.006 2.348-3.442zm10.117 2.724c-1.164 2.799-3.113 3.605-3.23 3.651l-.005.002-3.393-2.383 6.628-1.27zm-8.936-5.233L7.81 16.85c-1.136-2.81-.327-4.78-.277-4.897l.002-.005 4.058-.763zm8.76-.081c1.137 2.81.33 4.78.28 4.897l-.003.005-4.058.764 3.781-5.666zM16.5 7.536c2.759 1.182 3.553 3.158 3.599 3.277l.002.005-2.349 3.442L16.5 7.536zm-.411-.104l.005.003.754 4.116-5.586-3.836c2.77-1.154 4.712-.333 4.827-.283zm-5.217.451l3.392 2.382-6.628 1.271c1.202-2.885 3.236-3.653 3.236-3.653z" fill="#FFF"/></g></svg>
      <span className='pl-3 text-base'>PITZ MEDIA</span>
    </Navbar.Brand>

    <Navbar.Toggle aria-controls='basic-navbar-nav' />
    {auth.token && (<Navbar.Collapse id='basic-navbar-nav'>
      <Nav className='d-flex flex-fill justify-content-center'>
        <NavLink className={`mr-4 ${css.NavLink}`} to='/video' activeClassName={css.active}>
          Modo narración
        </NavLink>
      </Nav>
    </Navbar.Collapse>)}

    {auth.token && (
      <Button onClick={signout} className={`ml-auto ${css['Header-button']}`} type='button'>
        Cerrar sesión
      </Button>
    )}
  </Navbar>
);

const mapDispatch = (dispatch) => ({
  signout: () => dispatch.auth.signout(),
});

const mapState = (state) => ({
  auth: state.auth,
});

export default connect(mapState, mapDispatch)(Header);
