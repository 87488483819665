import React from 'react';
import { Button, Form, Container, Col, Row, Card } from 'react-bootstrap';
import Header from './Header/Header'
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { history } from '../_helpers/history';

class Login extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      submitted: false
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ ...prevState, submitted: true }));

    const { email, password } = this.state;
    if (email && password) {
      this.props.signin({ email, password });
    }
  }

  componentDidUpdate() {
    if (this.props.auth.token) {
      history.push('/video');
    }
  }

  render() {
    const { email, password, submitted } = this.state;
    const { auth } = this.props;

    return (
      <>
        <Header />
        <Container className="mt-3">
          <Row>
            <Col xs="6" className="m-auto">
              <Card>
                <Card.Header>Iniciar Sesión</Card.Header>

                <Card.Body>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Correo</Form.Label>
                      <Form.Control type="email" placeholder="Correo" name="email" value={email} onChange={this.handleChange} />
                      {submitted && !email &&
                        <div className="label label-danger">email is required</div>
                      }
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                      <Form.Label>Contraseña</Form.Label>
                      <Form.Control type="password" placeholder="Contraseña" name="password" value={password} onChange={this.handleChange} />
                      {submitted && !password &&
                        <div className="help-block">Password is required</div>
                      }
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Iniciar Sesión
                    </Button>
                  </Form>

                    {auth.error && (
                      <Alert className='mt-4' variant='danger'>
                        {auth.error.message}
                      </Alert>
                    )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatch = dispatch => ({
  signin: (body) => dispatch.auth.signin(body)
});

const mapState = state => ({
  auth: state.auth
});

export default connect(mapState, mapDispatch)(Login);
